@import url('https://fonts.googleapis.com/css?family=Montserrat');

.App {
  background-color: #282c34;
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

:root {
  --amplify-primary-color: #c00000;
  --amplify-primary-tint: #9a0000;
  --amplify-primary-shade: #c61a1a;
  --button-background-color: #c00000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
